
import { CameraStatus } from "@evercam/shared/types"

export default {
  name: "CameraStatusChip",
  props: {
    status: {
      type: String,
      default: () => "",
    },
  },
  computed: {
    cameraStatuses() {
      return {
        [CameraStatus.ONLINE]: {
          text: "Online",
          color: "success",
        },
        [CameraStatus.OFFLINE]: {
          text: "Offline",
          color: "error",
        },
        [CameraStatus.OFFLINE_SCHEDULED]: {
          text: "Offline (Scheduled)",
          color: `${this.$vuetify.theme.dark ? "#b22a20" : "#f43f5e"}`,
        },
        [CameraStatus.WAITING]: {
          text: "Waiting",
          color: "",
        },
        [CameraStatus.UNDER_MAINTENANCE]: {
          text: "Under Maintenance",
          color: `${this.$vuetify.theme.dark ? "#ffca28" : "#ffc107"}`,
        },
        [CameraStatus.WAITING_FOR_SITE_VISIT]: {
          text: "Waiting For Site Visit",
          color: `${this.$vuetify.theme.dark ? "#ffca28" : "#ffc107"}`,
        },
        [CameraStatus.DECOMMISSIONED]: {
          text: "Decommissioned",
          color: "",
        },
        [CameraStatus.ON_HOLD]: {
          text: "On Hold",
          color: "",
        },
      }
    },
    currentStatus() {
      return this.cameraStatuses?.[this.status]
    },
  },
}
